import cx from "classnames";
import React from "react";
import css from "./DropdownList.module.css";
import checkmark from "@/assets/blueCheck.svg";
import {useTranslation} from "@/util/helper";



function DropdownList(props) {

    const {t} = useTranslation();
    const {isOpen, options, select, selected, className, checkmarkVisible, theme, translateKeys} = props;
    const valueKey = props.valueKey ? props.valueKey : "value";
    const labelKey = props.labelKey ? props.labelKey : "label";
    const typeClass = checkmarkVisible ? "extended" : "";

    if (!isOpen) return <></>;

    return <>
        <div className={cx(css.list, className, css[typeClass], css[theme])}>
            {options.map(option =>
                <div
                    key={option[valueKey]}
                    onClick={() => select(option)}
                    className={cx(css.listItem, {[css.selected]: selected && selected[valueKey] === option[valueKey]})}>
                        {checkmarkVisible && <div className={css.checkmark}>
                            {selected && selected[valueKey] === option[valueKey] ? <img src={checkmark} /> : <div></div>}
                        </div>}
                    {translateKeys ? t(option[labelKey]) : option[labelKey]}
                </div>
            )}
        </div>
        </>
}

export default DropdownList;
