import { getApiClient } from "@/api/util";

export default (ctx) => ({
  getUserCompanies: () => getApiClient(ctx).get(`/v3/lab4pay/holistic/company`),
  getStores: (companyId) =>
    getApiClient(ctx).get(`/v3/lab4pay/holistic/${companyId}/stores`),
  getTerminals: (companyId) =>
    getApiClient(ctx).get(`/v3/lab4pay/holistic/${companyId}/terminals`),
  getPaymentMethods: (companyId, all) =>
    getApiClient(ctx).get(
      `/v3/lab4pay/holistic/${companyId}/transaction/methods`,
      {
        params: {
          all,
        },
      }
    ),
});
