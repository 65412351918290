import auth from "@/api/auth/auth";
import { setUser } from "@/redux/user";
import cookies from "next-cookies";
import { eraseCookie, Router } from "@/util/helper";
import NProgress from "nprogress";
import { setCurrencies } from "@/redux/currencies";
import currencies from "@/api/currencies/currencies";
import { setLanguages } from "@/redux/languages";
import getConfig from "next/config";
import i18n from "../i18n";
import { getSafeDeep } from "@/util/state";
import { getApiClient } from "@/api/util";
import common from "@/api/common/common";
import { setCompanies } from "../redux/common";
import { setCompanyCurrency } from "../redux/companyCurrency";
import { SELECTED_COMPANY, SELECTED_MD_ORGANIZATION } from "@/util/constants";

const { publicRuntimeConfig } = getConfig();
const sessionCookieName = process.env.sessionCookieName || "sessionid";

export const preloadGlobalResources = async (ctx) => {
  try {
    const store = ctx.reduxStore;
    const language = getSafeDeep(ctx.req, "i18n.language", false);
    const storeState = store.getState();

    const [
      currencyList,
      languageList,
      stores,
      terminals,
      statuses,
      methods,
    ] = await Promise.all([
      cachePromiseWrapper(
        currencies(ctx).getCurrencies,
        storeState,
        "currencies"
      ),
    ]);
    store.dispatch(setCurrencies(currencyList));
    store.dispatch(
      setLanguages([
        { code: "SL", name: "Slovenian", native: "Slovenščina" },
        { code: "RO", name: "Romanian", native: "Română" },
        { code: "EN", name: "English", native: "English" },
      ])
    );
  } catch (e) {
    console.log(getSafeDeep(e, "response.data"));
  }
};

export const preloadStore = async (ctx: ProjectPageContext): Promise<void> => {
  const store = ctx.reduxStore;
  const lang = geti18nLanguage(ctx);
  getApiClient(ctx).defaults.headers.common["Accept-Language"] = lang;
  getApiClient(ctx).defaults.headers.common.Language = lang;

  const settings = (await auth(ctx).getSettings(lang)).data;
  const userCompanies = (await common(ctx).getUserCompanies()).data;
  // const companyCurrency = (await currencies(ctx).getCurrency(userCompanies[0].id)).data;

  // if (!cookies(ctx)["next-i18next"] && ctx.req) {
  //     await ctx.req.i18n.changeLanguage(settings.language)
  // }

  if (!ctx.req) {
    NProgress.inc();
  }
  store.dispatch(setCompanies(userCompanies));
  // store.dispatch(setCompanyCurrency(companyCurrency));

  if (!ctx.req) {
    NProgress.inc();
  }
};

export const merchantRoleIds = [
  "00000000-ffff-ffff-ffff-ffffffffffff",
  "00000000-ffff-ffff-ffff-000000000000",
];

export const redirectIfAuth = async (
  ctx: ProjectPageContext
): Promise<void> => {
  if (ctx.req && !cookies(ctx)[sessionCookieName]) {
    return true;
  }
  try {
    if (!ctx.req) {
      NProgress.inc();
    }

    let roles;
    try {
      const { data } = await auth(ctx).getRole();
      roles = data;
    } catch (e) {
      roles = [];
    }
    const hasMerchantRole = roles.some(({ id }) =>
      merchantRoleIds.includes(id)
    );
    if (hasMerchantRole) {
      await redirectTo(ctx, "/");
      return false;
    }
    return true;
  } catch (e) {
    if (!(e.response && e.response.status === 403)) {
      // throw e
      return true;
    }
  }
};

const cachePromiseWrapper = (apiCall, storeState, key, apiDataKey) =>
  new Promise((resolve, reject) => {
    if (!storeState[key]) {
      apiCall().then((response) => {
        if (apiDataKey) resolve(response.data[apiDataKey]);
        else resolve(response.data);
      });
    } else {
      resolve(storeState[key]);
    }
  });

/**
 * Get authenticated user or redirect to login page
 * @param ctx {ProjectPageContext}
 */
export const requireAuthenticatedUser = async (
  ctx: ProjectPageContext
): Promise<User> => {
  if (ctx.req && !cookies(ctx)[sessionCookieName]) {
    await redirectTo(ctx, "/login");
    return false;
  }

  let roles;
  try {
    const { data } = await auth(ctx).getRole();
    roles = data;
  } catch (e) {
    roles = [];
  }
  const hasMerchantRole = roles.some(({ id }) => merchantRoleIds.includes(id));
  if (!hasMerchantRole) {
    await redirectTo(ctx, "/login");
    return false;
  }
  try {
    await preloadStore(ctx);

    return true;
  } catch (e) {
    if (e.response && e.response.status === 403) {
      console.log("err", e.response);
      await redirectTo(ctx, "/login");
      return false;
    }
    throw e;
  }
};

export const geti18nLanguage = (ctx) =>
  ctx.req
    ? getSafeDeep(ctx.req, "language", "en")
    : getSafeDeep(i18n, "i18n.language", "en");

export async function redirectTo(ctx, path) {
  if (ctx.req) {
    ctx.res.writeHead(302, { Location: path }).end();
    ctx.res.finished = true;
  } else {
    await Router.push(path);
  }
}

export const handleLogout = () => {
  auth().logout();
  eraseCookie("sessionid");
  eraseCookie(SELECTED_COMPANY);
  eraseCookie(SELECTED_MD_ORGANIZATION);
  window.location.replace(`${publicRuntimeConfig.ellyManagerURL}/login`);
};
