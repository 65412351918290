import React from "react";
import css from "./Button.module.css";
import cx from "classnames";
import SVG from "react-inlinesvg";

function Button(props, ref) {
  const color = props.color ? props.color : "white";
  const size = props.size ? props.size : "normal";
  const fontWeight = props.fontWeight ? props.fontWeight : "w400";
  const isLoading = props.isLoading ? "loading" : "";
  const borderless = props.borderless ? "noRound" : "";
  const fontSize = props.fontSize ? `${props.fontSize}Font` : "normalFont";
  const isActive = props.isActive ? "active" : "";
  const activeClassName =
    props.activeClassName && isActive ? props.activeClassName : "";
  const { type } = props;
  const { disabled } = props;
  const { icon } = props;
  const { iconAfter } = props;
  const hasIcon = props.icon ? "hasIcon" : "";
  return (
    <button
      onClick={disabled || isLoading ? () => {} : props.onClick}
      onMouseEnter={disabled || isLoading ? () => {} : props.onMouseEnter}
      onMouseLeave={disabled || isLoading ? () => {} : props.onMouseLeave}
      type={type}
      className={cx(
        props.className,
        css.button,
        { [css.disabled]: disabled },
        css[color],
        css[isActive],
        css[size],
        css[fontWeight],
        css[fontSize],
        css[hasIcon],
        css[isLoading],
        css[borderless],
        activeClassName
      )}
    >
      {icon && <SVG src={icon} className={cx(css.icon, props.iconClass)} />}
      {isLoading ? (
        <div className={cx(`dot-pulse-${color}-bg`)} />
      ) : (
        props.children
      )}
      {iconAfter && (
        <SVG
          src={iconAfter}
          className={cx(css.iconAfter, props.iconAfterClass)}
        />
      )}
    </button>
  );
}

export default Button;

export function GrayButton(props) {
  return (
    <Button {...props} color="gray" fontSize="large" size="normal">
      {props.children}
    </Button>
  );
}

export const NormalButton = React.forwardRef((props, ref) => (
  <Button {...props}>{props.children}</Button>
));

export function LargeButton(props) {
  return (
    <Button {...props} color="white" size="large">
      {props.children}
    </Button>
  );
}

export function LargeBlueButton(props) {
  return (
    <Button {...props} color="blue" fontSize="large" size="large">
      {props.children}
    </Button>
  );
}

export function NormalBlueButton(props) {
  return (
    <Button {...props} color="blue" fontSize="large" size="normal">
      {props.children}
    </Button>
  );
}

export function LargeYellowButton(props) {
  return (
    <Button {...props} color="yellow" size="large">
      {props.children}
    </Button>
  );
}

export function SmallButton(props) {
  return (
    <Button {...props} color="white" size="small" fontSize="small">
      {props.children}
    </Button>
  );
}

export function SmallerButton(props) {
  return (
    <Button {...props} color="white" size="small" fontSize="smaller">
      {props.children}
    </Button>
  );
}

export function TransparentButton(props) {
  return (
    <Button {...props} color="transparent" fontWeight="w500" fontSize="large">
      {props.children}
    </Button>
  );
}

export function SmallTransparentButton(props) {
  return (
    <Button {...props} color="transparent" fontWeight="w500" fontSize="small">
      {props.children}
    </Button>
  );
}

export const BlackButton = React.forwardRef((props, ref) => (
  <Button {...props} color="black" fontWeight="w400" fontSize="large">
    {props.children}
  </Button>
));

export const SwitchButton = ({ children, subtitle, onClick, icons, image }) => (
  <button type="button" className={css.picker__button} onClick={onClick}>
    <span className={css.button__icon}>
      {icons[0] ? (
        <div className={css.button__icon__wrapper}>
          <img src={icons[0]} alt={subtitle} height="40px" width="40px" />
        </div>
      ) : (
        <img src={image} alt={subtitle} height="40px" width="40px" />
      )}
    </span>
    <div className={css.button__text}>
      <span className={css.text__title}>{children}</span>
      {subtitle && <span className={css.text__subtitle}>{subtitle}</span>}
    </div>
    <SVG src={icons[1]} />
  </button>
);
